import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEOPage';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { wrapper, breakpoints, ContentStyles, color, font } from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import Parallax from '../../components/shared/Backgrounds/Parallax';
import IcomoonIcon from '../../components/shared/IcomoonIcon';
import NewsBanners from '../../components/Content/LottoNews/NewsBanners';
import PlayBestLottery from '../../components/Content/Home/PlayBestLottery';
import BottomBanner from '../../components/Content/Home/BottomBanner';
import ContentFromWp from '../../components/shared/ContentFromWP';

const WrapperDesktop = styled("div")`
  ${wrapper}
  .top-lottery-block {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .lotto-results-block {
    flex-basis: 31.6%;
    text-align: center;
  }
  
  .outer-gradient-wrap {
    border-radius: 50px 20px 20px 20px;
    & > .inner {
      padding: 30px 20px;
      border-radius: 49px 19px 19px 19px;
    }
  }
  
  .red-h3 {
    color: ${color.red};
    font-weight: ${font.boldWeight};
    margin-bottom: 20px;
  }
  .date-info {
    margin-bottom: 20px;
  }
  
  .lotto-figure-wrap {
    border-radius: 14px;
    margin-bottom: 20px;
    .inner {
      border-radius: 13px;
      padding: 15px;
      background: ${color.white};
    }
    p {
      color: ${color.likeGrey};
      line-height: 1.5;
      margin-bottom: 4px;
    }
    .result {
      color: ${color.red};
      font-size: 20px;
      line-height: 1.2;
      font-weight: ${font.boldWeight};
    }
  }
  
  .top-wrap {
    flex-basis: 65.4%;
    padding: 40px;
    background-image: linear-gradient(to right, #fff9f8, #effffd);
    box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.05);
    border-radius: 100px 20px 100px 20px;
  }
  .title-wrap {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .logo-wrapper {
    flex-basis: 128px;
    .gatsby-image-wrapper {
      border-radius: 100%;
    }
  }
  .h1 {
    padding-left: 40px;
  }
  .lotto-descr {
    margin: 40px 0 10px;
    ${ContentStyles}
    max-height: 110px;
    overflow: hidden;
  }
  
  .full-height {
    max-height: 50000px;
    & + .show-more {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .show-more {
    display: inline-flex;
    color: ${color.red};
    background: transparent;
    padding: 0;
    &:hover, &:active {
      background: transparent;
    }
    svg {
      margin-left: 10px;
    }
    .more-span {
      display: inline-flex;
    }
    .less-span {
      display: none;
    }
  }
  .show-less {
    .more-span {display: none;}
    .less-span {display: inline-flex;}
  }
  
  .register-button {
    min-width: 30%;
  }
  
  .middle-lottery-block {
    margin: 40px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 60px 0;
  }
  
  .iframe-container {
    padding-top: 90%;
  }
  
  .right-banners {
    flex-basis: 31.6%;
  }
  
  .lotto-info-container {
    flex-basis: 65.4%;
    & > .inner {
      height: 100%;
    }
    .logo-wrapper {
      flex-basis: 97px;
    }
    .gatsby-image-wrapper {
      width: 72px;
    }
    .date-range {
      margin-left: auto;
      color: ${color.likeGrey};
    }
  }
  
  .iframe-wrap {
    margin-top: 25px;
    border-radius: 20px;
    & > .inner {
      border-radius: 19px;
      padding: 20px;
      background: ${color.white};
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .lotto-info-container {
      flex-basis: 100%;
    }
    .right-banners {
      flex-basis: 100%;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .top-wrap {
      flex-basis: 100%;
      background-image: linear-gradient(to left, #57ffeb, #ff989c);
      padding: 1px;
      border-radius: 50px 20px 20px 20px;
      margin-bottom: 25px;
      .mobile-inner {
        background-image: linear-gradient(to right, #fff9f8, #effffd);
        padding: 20px 10px;
         border-radius: 49px 19px 19px 19px;
      }
    }
    .lotto-results-block {
      flex-basis: 100%;
    }
    .logo-wrapper {
      flex-basis: 72px;
      margin-right: 20px;
    }
    .h1 {
      padding: 0;
      margin: 0;
    }
    .lotto-descr {
      margin-top: 20px;
    }
    .red-h3 {
      font-size: 24px;
    }
    .register-button {
      min-width: 45%;
    }
    .middle-lottery-block {
      margin: 25px 0 5px;
      .title-wrap {
        display: block;
        text-align: center;
      }
      .logo-wrapper {
        display: none;
      }
      .h3 {
        font-size: 24px;
      }
      .date-range {
        margin: 0 auto;
      }
    }
    .iframe-container {
      padding-top: 200%;
    }
    .iframe-wrap {
      & > .inner {
        padding: 20px 0;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .h1 {
      font-size: 24px;
    }
  }
`;

const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  const lotteryLogo = currentPage.acf?.lotto_provider_logo?.localFile?.childImageSharp?.fluid;
  const metaTitle = (currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title)
    ? currentPage.yoast_meta.yoast_wpseo_title : currentPage.title + "- " + process.env.OWNER;
  const metaDesc = (currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc)
    ? currentPage.yoast_meta.yoast_wpseo_metadesc : '';

  const homePage = data.home.edges[0].node;

  const rightBanner1 = currentPage.acf.right_banner_1 ? currentPage.acf.right_banner_1 :
    homePage.acf.description_banner_1;
  const rightBanner2 = currentPage.acf.right_banner_1 ? currentPage.acf.right_banner_2 :
    homePage.acf.description_banner_2;

  const handleMoreClick = e => {
    e.preventDefault();
    const target = document.getElementById("lotto-text-content");
    target.classList.toggle("full-height");
    const self = document.getElementById("home-more-less");
    self.classList.toggle("show-less");
  };

  const today = new Date();
  const todayMinusMonth = new Date(today - 1000 * 60 * 60 * 24 * 30);

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
      />
      <Parallax />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/>
        <div className="top-lottery-block">
          <div className="top-wrap">
            <div className="mobile-inner">
              <div className="title-wrap">
                {lotteryLogo &&
                <div className="logo-wrapper" key="logo-wrapper">
                  <Img fluid={lotteryLogo}
                       alt={(currentPage.acf.lotto_provider_logo.alt_text) ? currentPage.acf.lotto_provider_logo.alt_text
                         : page_title} fadeIn={false} loading='eager' />
                </div>
                }
                <h1 className="h1 a-center gradient" dangerouslySetInnerHTML={{ __html: page_title }} />
              </div>
              {currentPage.acf?.lotto_provider_description &&
              <>
                <div className="lotto-descr" id="lotto-text-content"
                     dangerouslySetInnerHTML={{ __html: currentPage.acf.lotto_provider_description }} />
                <button id="home-more-less" aria-label="Show more" className="button no-button show-more"
                        onClick={e => handleMoreClick(e)}>
                  <span className="more-span">อ่านเพิ่มเติม</span>
                  <span className="less-span">แสดงน้อยลง</span>
                  <IcomoonIcon icon="arrow-down-simple" color={color.red} size={16}/>
                </button>
              </>
              }
              <div className="a-center">
                <Link className="register-button button gradient-border-button" to={'/register/'}><span>Register</span></Link>
              </div>
            </div>

          </div>
          <div className="lotto-results-block">
            <div className="gradient-wrap outer-gradient-wrap">
              <div className="inner">
                <h3 className="red-h3">ผลการจับสลาก</h3>
                <p className="date-info">งวดประจำวันสำหรับ {today.toLocaleDateString()}</p>
                {currentPage.acf?.lotto_results_set_4 &&
                <div className="gradient-wrap lotto-figure-wrap">
                  <div className="inner">
                    <p>ชุดผลลัพธ์ตัวเลข 4 ตัว</p>
                    <span className="result">{currentPage.acf.lotto_results_set_4}</span>
                  </div>
                </div>
                }
                {currentPage.acf?.lotto_results_top_3 &&
                <div className="gradient-wrap lotto-figure-wrap">
                  <div className="inner">
                    <p>เลข 3 ตัวบน</p>
                    <span className="result">{currentPage.acf.lotto_results_top_3}</span>
                  </div>
                </div>
                }
                {currentPage.acf?.lotto_results_bottom_2 &&
                <div className="gradient-wrap lotto-figure-wrap">
                  <div className="inner">
                    <p>เลข 2 ตัวล่าง</p>
                    <span className="result">{currentPage.acf.lotto_results_bottom_2}</span>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="middle-lottery-block">
          <div className="lotto-info-container gradient-wrap outer-gradient-wrap">
            <div className="inner">
              <div className="title-wrap">
                {lotteryLogo &&
                <div className="logo-wrapper" key="logo-wrapper">
                  <Img fluid={lotteryLogo}
                       alt={(currentPage.acf.lotto_provider_logo.alt_text) ? currentPage.acf.lotto_provider_logo.alt_text
                         : page_title} fadeIn={false} loading='eager' />
                </div>
                }
                <h3 className="h3 a-center gradient">Check {page_title} history</h3>
                <span className="date-range">{todayMinusMonth.toLocaleDateString()} - {today.toLocaleDateString()}</span>
              </div>
              <div className="iframe-wrap gradient-wrap">
                <div className="inner">
                  {currentPage.acf?.lotto_provider_iframe ?
                    <div className="iframe-container">
                      <iframe title="Lotto results iframe" src={currentPage.acf.lotto_provider_iframe}/>
                    </div>
                    : <div className="a-center">No results right now :(</div>
                  }
                </div>
              </div>


            </div>
          </div>
          <div className="right-banners">
            <NewsBanners banner1={rightBanner1}
                         banner2={rightBanner2}/>
          </div>
        </div>

        <ContentFromWp>
          <div dangerouslySetInnerHTML={{ __html: currentPage.content }}/>
        </ContentFromWp>

        {homePage.acf.lotteries.lotteries.length &&
        <PlayBestLottery title={homePage.acf.lotteries.lotteries_title} subtitle={homePage.acf.lotteries.lotteries_subtitle}
                         lottery_images={homePage.acf.lotteries.lotteries}/>}
        <BottomBanner banner={homePage.acf.bottom_banner}/>
      </WrapperDesktop>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
        lotto_provider_iframe
        lotto_provider_description
        lotto_provider_logo {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 128,quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          } 
        }
        lotto_results_set_4
        lotto_results_top_3
        lotto_results_bottom_2
        right_banner_1 {
          acf {
            slide_url
            slide_title
            slide_description
          }
          localFile {
            childImageSharp {
              fluid(maxWidth: 375, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
            
        right_banner_2 {
          acf {
            slide_url
            slide_title
            slide_description
          }
          localFile {
            childImageSharp {
              fluid(maxWidth: 375, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }  
      } 
    }
    home: allWordpressPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          acf {
            description_banner_1 {
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            
            description_banner_2 {
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            lotteries {
              lotteries_title
              lotteries_subtitle
              lotteries {
                color
                link
                tex
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 128, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
            bottom_banner {
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1190, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }  
    }
  }
`;
