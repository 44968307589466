import React, { memo } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/style';

const NewsBannersWrap = styled('div')`
  .desc-banner {
    display: block;
    border-radius: 20px;
    margin-bottom: 30px;
    &:hover, &:active {
      box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.05);
    }
  }
  .gatsby-image-wrapper {
    border-radius: 20px;
  }
  @media (max-width: ${breakpoints.md}) {
    margin-top: 25px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .desc-banner {
      flex-basis: 48%;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .desc-banner {
      flex-basis: 100%;
      margin-bottom: 25px;
    }
  }
`;




const NewsBanners = ({ banner1, banner2 }) => {


  return (
    <NewsBannersWrap>
      {banner1.acf?.slide_url ?
        <a className="desc-banner" href={banner1.acf.slide_url}>
          <Img
            fluid={banner1.localFile.childImageSharp.fluid}
            alt={banner1.alt_text}
            fadeIn={false}
            loading='eager'
          />
          </a> : <div className="desc-banner">
            <Img
              fluid={banner1.localFile.childImageSharp.fluid}
              alt={banner1.alt_text}
              fadeIn={false}
              loading='eager'
                    />
          </div>
      }
      {banner2.acf?.slide_url ?
        <a className="desc-banner desc-banner-bottom" href={banner1.acf.slide_url}>
          <Img
            fluid={banner2.localFile.childImageSharp.fluid}
            alt={banner2.alt_text}
            fadeIn={false}
            loading='eager'
          />
        </a> : <div className="desc-banner desc-banner-bottom">
          <Img
            fluid={banner2.localFile.childImageSharp.fluid}
            alt={banner2.alt_text}
            fadeIn={false}
            loading='eager'
          />
        </div>
      }
    </NewsBannersWrap>
  );
};

NewsBanners.propTypes = {
  banner1: PropTypes.shape({
    alt_text: PropTypes.string,
    acf: PropTypes.shape({
      slide_description: PropTypes.string,
      slide_title: PropTypes.string,
      slide_url: PropTypes.string,
    }),
  }),
  banner2: PropTypes.shape({
    alt_text: PropTypes.string,
    acf: PropTypes.shape({
      slide_description: PropTypes.string,
      slide_title: PropTypes.string,
      slide_url: PropTypes.string,
    }),
  }),
};

NewsBanners.defaultProps = {
  banner1: {},
  banner2: {},
};

export default memo(NewsBanners);
